import React, {useContext, useEffect} from 'react';
import { Link } from "react-router-dom";
import { Breathing } from "react-shimmer";
import SwiperCore, {Navigation, Pagination, Autoplay, EffectFade} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import Skeleton from "react-loading-skeleton";
import {
    baseUrl,
    convertToSlug,
    dateFormat,
    isLogin,
    placeholderImage,
    toBase64,
    translate,
    truncateText
} from "../utils";
import { useState } from "react";
import $ from 'jquery';

import {selectUser} from "../store/reducers/userReducer";
import {useSelector} from "react-redux";
import RateCard from "./RateCard";
import Button from "react-bootstrap/Button";
import {
    getAdSpaceApi,
    getPublicationApi,
    getSubscriptionsApi,
    getUserSubscriptionsApi
} from "../store/actions/campaign";
import {now} from "moment";
import {getAdSpacesApi} from "../utils/api";
import {selectCurrentLanguage} from "../store/reducers/languageReducer";

SwiperCore.use([Navigation, Pagination,Autoplay]);

const StyleTodayNewspaper = ({ isLoading, Data }) => {

    const userData = useSelector(selectUser);

    const [showRateCard, setShowRateCard] = useState(false);
    const [banners, setBannas] = useState([]);

    const [rateCard, setRateCard] = useState({});

    const [subscription, setSubscription] = useState([]);
    const currentLanguage = useSelector(selectCurrentLanguage);
    const [isLoadingLogin, setisloginloading] = useState(false);

    const [isLoadingBanner, setIsLoadingBanner] = useState(false);
    const [subscriptionRequest, setSubscriptionRequest] = useState(false);


    let swiperOption = {
        loop: true,
        speed: 750,
        spaceBetween: 5,
        slidesPerView: 6,
        navigation: false,
        autoplay:{
            delay: 10000,
            disableOnInteraction: false,
        },
        pagination: false,
    };
    let bannerOption = {
        loop: true,
        speed: 750,
        spaceBetween: 5,
        slidesPerView: 1,
        navigation: false,
        autoplay:{
            delay: 5000,
            disableOnInteraction: false,
        },
        pagination: false,
    };

    if(window.innerWidth <= 600){
        swiperOption.slidesPerView = 1;
    }else if(window.innerWidth < 800){
        swiperOption.slidesPerView = 2;
    }
    useEffect(() => {

        isUserSubscribed();
        if (isLogin()) {
            setisloginloading(false);
        } else {
            setisloginloading(true);
        } // eslint-disable-next-line
    }, []);

    function showHandle(item) {
        let i = item.pages != null ? item.pages : 0,
            uid = userData.data != null ? userData.data.id : 0,
            url = '/read/' + toBase64(item.cover + "~" + uid) + '/' + i;

        if(item.free === '1'){
            return window.location.href = url;
        }

        if (subscription.length === 0) {
            return handleRateCard(item);
            // return $("#btnSignIn").click();
        }

        if (subscription.length > 0) {
            subscription.map((d)=>{

                var exp = Date.parse(d["expired_at"]),
                    now = new Date(),
                    f = d["frequency"].toLowerCase(),
                    brands = [];

                if (f !== "daily") {
                    if (d['brand'].toString().length === 1) {
                        brands = [d["brand"]];
                    } else {
                        brands = d["brand"].split(',');
                    }

                    let contains = brands.includes(item.bid),
                        sub = contains && exp >= now;

                    if (sub)
                        return window.location.href = url;
                    else
                        return handleRateCard(item);
                } else {
                    let sub = item.bid.toString().toLowerCase() ===
                        d["brand"].toString().toLowerCase() &&
                       item.id.toString() === d["pid"].toString();

                    if (sub)
                        return window.location.href = url;
                    else
                        return handleRateCard(item);
                }
            })
        }else
            return handleRateCard(item);
    }

    function handleRateCard(item){
        setShowRateCard(true);
        setRateCard({"id": item.id, "bid": item.bid, "paper": item.brand,"title": item.title, "cover": item.cover,"pages": item.pages});
        return false;
    }

//getUserSubscriptionsApi
    function isUserSubscribed(){
        setSubscriptionRequest(true);
        let pid = localStorage.getItem('pid'),
            bid = localStorage.getItem('bid');

        if(typeof pid != 'undefined' || typeof bid != 'undefined') {
            pid = '';
            bid = '';
        }
        getUserSubscriptionsApi({"pid": pid, "bid": bid,"corporate_id": userData.data != null ? userData.data.corporate_id :''}, ((data) => {

                if(data.error !== 'true'){
                    // setIsSubscribed(true);
                    setSubscriptionRequest(false);
                    setSubscription(data.data);
                }
            }), ((error) => {
                // console.log(error);
                setSubscriptionRequest(false);
            }))

        return false;
    }

    useEffect(()=>{
        getAdSpaceApi(
            (response) => {
                const responseData = response.data;
                setBannas(responseData);
                setIsLoadingBanner(false);
            },
            (error) => {
                setIsLoadingBanner(false);
                if (error === "No Data Found") {
                    setBannas('');
                }
            },
            ()=>{},
            'web'
        );
    },[currentLanguage]);

    return (
        <div id="first-section">

            {/*banner*/}
            <div className="home-banner">
                <div className="container banner">
                    {(banners && banners?.length > 0) ?
                        (
                            <Swiper {...bannerOption} className="custom-swiper">
                                {isLoadingBanner ? (
                                    // Show skeleton loading when data is being fetched
                                    <div className="col-12 col-sm-12 loading_data">
                                        <Skeleton height={20} count={22} />
                                    </div>
                                ) :(

                                    banners.map((product) =>(<SwiperSlide key={"np-"+product.id} className="product1 col-sm-12">
                                       <div className="banner-overlay" style={{ backgroundImage: `url(${product.web})` }}></div>
                                        <img src={product.web} />
                                    </SwiperSlide>))

                                    )}
                            </Swiper>
                                ) : null
                    }

                </div>
            </div>

            {/* news section */}
            <div className="container py-5">
                <div className="row">
                    <div className="col-lg-11 col-md-10 col-sm-9 my-3 top-title">{translate('todayLbl')}</div>
                    <div className="col-lg-1 col-md-2 col-sm-3 float-end"><Link to="/newspaper" className="seel">See All</Link></div>
                </div>
                {(Data && Data.data?.length > 0) ? (
                        <Swiper {...swiperOption} className="custom-swiper">
                            {isLoading ? (
                                    // Show skeleton loading when data is being fetched
                                    <div className="col-12 col-sm-12 loading_data">
                                        <Skeleton height={20} count={22} />
                                    </div>
                                ) :
                                (

                                    <div className="row mt-3">
                                        {(
                                            Data.data.map((product) => (
                                                <div className="col-sm-12 col-lg-2 pointer " key={product.id} onClick={()=>showHandle(product)}>
                                                    <a onClick={()=>showHandle(product)}>
                                                        <div className="col-sm-12">
                                                            <img src={baseUrl() + "/public/docs/" + product.cover}
                                                                 id="fs-Newscard-image"
                                                                 fallback={<Breathing/>}
                                                                 alt={product.title} onError={placeholderImage}/>
                                                        </div>
                                                        <div className="col-sm-12 col-12 ">
                                                            <div
                                                                className="my-title">{truncateText(product.title, 30)}</div>
                                                            <p className="cat">
                                                                {truncateText(product.brand,)}
                                                                <span className="float-end">
                                                        {dateFormat([product.publish_at])}
                                                        </span>
                                                            </p>
                                                            <div className={"col-sm-12 pb-3 mb-4 read-btn "}
                                                                 onClick={() => showHandle(product)}>
                                                                <a className={"btn btn-primary mt-3 pointer "}>{truncateText("read")}</a>
                                                            </div>

                                                        </div>
                                                    </a>
                                                </div>
                                            ))
                                        )}
                                    </div>
                                )}

                            {showRateCard && (
                                <RateCard show={showRateCard} item={rateCard}
                                          onHide={() => setShowRateCard(false)}></RateCard>
                            )}
                        </Swiper>
                    )
                    : null}
            </div>

        </div>
    );
}

export default StyleTodayNewspaper;
