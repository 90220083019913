import React, {useState, useEffect, useContext} from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { AiOutlineClose } from "react-icons/ai";
import {useSelector} from "react-redux";
import {selectCurrentLanguage} from "../store/reducers/languageReducer";
import {getBrandsApi, getRateCardApi, getUserSubscriptionsApi} from "../store/actions/campaign";
import Skeleton from "react-loading-skeleton";
import {toBase64, truncateText, uFirst} from "../utils";
import Select from 'react-select'
import makeAnimated from 'react-select/animated';
import PaymentMethod from "./PaymentMethod";
import {selectUser} from "../store/reducers/userReducer";
import {loadcart} from "../store/reducers/cartbadgeReducer";
import {toast} from "react-toastify";
import {CartContext} from "../utils/cart";

function RateCard(props) {

    const [isLoading, setIsLoading] = useState(true);
    const [Data, setData] = useState([]);
    const [plan, setPlan] = useState({});
    const [brands, setbrands] = useState([]);
    const [paper, setPaper] = useState();
    const [isShow, setShowModel] = useState(false);

    const userData = useSelector(selectUser);

    const [isSingle, setSingle] = useState(true);

    const [isSubscription, setIsSubscription] = useState({});

    const options = [
    ]

    const { cartItems, addToCart , removeFromCart} = useContext(CartContext);

    const [items, setItem] = useState([]);


    const notifyAddedToCart = (item) => toast.success(`${item.title} added to cart!`, {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: 'colored',
        style: {
            backgroundColor: '#fff',
            color: '#000',
        }
    });

    const notifyRemovedFromCart = (item) => toast.error(`${item.title} removed from cart!`, {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: 'colored',
        style: {
            backgroundColor: '#000',
            color: '#fff',
        }
    });

    const handleRemoveFromCart = (product) => {
        removeFromCart(product);
        notifyRemovedFromCart(product);
    };

    // current language
    const currentLanguage = useSelector(selectCurrentLanguage);

    // breaking news api call
    useEffect(() => {
        setPaper(props.item.paper);
        getRates();
        getbrands();
        getSubscriptions();
    }, [currentLanguage]);

    function getSubscriptions(){
        let sub = false, subs = [];

        setIsLoading(true);

        let i = props.item.pages != null ? props.item.pages : 0;

        getUserSubscriptionsApi({"pid": props.item.id, "bid": props.item.bid}, ((data) => {

            if(props.item.free === '1'){
                setIsSubscription(
                    {"sub": '/read/' + toBase64(props.item.cover + "~" + userData.data.id) + '/' + i}
                );

                return;
            }

                if(data.error !== 'true'){
                    subs = data.data;
                    if (subs) {
                        if (Array.isArray(subs)) {

                            subs.map((d) => {
                                let exp = new Date(d.expired_at),
                                    f = d.frequency.toLowerCase();
                                if (f === "annually" || f === "half-yearly" || f === "quarterly" || f === "monthly" || f === "weekly") {
                                    sub = props.item.bid.toString().toLowerCase() === d.brand.toString().toLowerCase() && isInThePast(exp);

                                    setIsLoading(false);
                                    if(sub) {
                                        setIsSubscription(
                                            {"sub": '/read/' + toBase64(props.item.cover + "~" + userData.data.id) + '/' + i}
                                        );
                                    }

                                } else if (f === "daily") {
                                    sub = props.item.bid.toString().toLowerCase() === d.brand.toString().toLowerCase() && props.item.id.toString() === d.pid.toString() && isInThePast(exp);

                                    setIsLoading(false);
                                    if(sub) {
                                        setIsSubscription({"sub":  '/read/' + toBase64(props.item.cover + "~" + userData.data.id) + '/' + i});
                                    }

                                }
                            });
                        }
                    }
                }
            }))

    }

    function isInThePast(date) {
        const today = new Date();

        return date.getTime() > today.getTime();
    }

    const  getRates = function (){
        getRateCardApi(
            props.item.bid,
            (response) => {
                const responseData = response.data;
                setData(responseData);
                setIsLoading(false);
            },
            (error) => {
                setIsLoading(false);
                if (error === "No Data Found") {
                    setData('');
                }
            }
        );
    }

    const  getbrands = function (){
        getBrandsApi(
            (response) => {
                const responseData = response.data;
                setbrands(responseData);
                setIsLoading(false);
            },
            (error) => {
                setIsLoading(false);
                if (error === "No Data Found") {
                    setData('');
                }
            }
        );
    }

    const getPlan = function (){

        addToCart(items);

        notifyAddedToCart(items);
       let e = document.querySelector("#vps-modal-btnclose");
        if(e != null) {
            e.click();
        }
    }

    const addPlan = function (el,item){
        Object.assign(item, {"title": props.item.title,"cover":props.item.cover,"pid":props.item.id, "bid":props.item.bid,"brand": props.item.paper});
        let e = el.target.parentElement.lastChild.childNodes.item(el.target.parentElement.lastChild.childNodes.length - 1),
            p = el.target.parentElement.childNodes;

        if(p.length > 0){

            let els = document.querySelectorAll(".bg-tc");
            if(els.length > 0){
                els.forEach((el)=>{
                    el.classList.remove("bg-tc");
                });
            }

            p.forEach((el)=>{

                if(el.classList != null && typeof el.classList != 'undefined' && el.type !== 'radio') {
                    el.classList.add("bg-tc")
                }else{
                    el.parentElement.parentElement.childNodes.forEach((el)=>{
                        el.classList.add("bg-tc");
                    });

                }
            });
        }
        if (e != null)
            e.checked = true;

        item.method = Data.payment_method;
        setItem(item);
    }

    const addToStore = function (name,key, value) {
        // Get the existing data
        var existing = localStorage.getItem(name);

        // If no existing data, create an array
        // Otherwise, convert the localStorage string to an array
        existing = existing ? JSON.parse(existing) : {};

        // Add new data to localStorage Array
        existing[key] = value;
        localStorage.setItem(name, JSON.stringify(existing));
    };

    if(typeof isSubscription.sub != 'undefined')
        window.location.href = isSubscription.sub;

    return (
        <Modal {...props} size="x" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Body id="vps-modal-body">
                {isLoading ? (
                    <div className="loader-container">
                        <span className="loader"></span>
                    </div>
                ) : (
                    <div>

                        {isSubscription && typeof isSubscription.sub === 'undefined' && (

                        <div className="bg-white h-screen col-12">

                            <Button id="vps-modal-btnclose" onClick={props.onHide}>
                                <AiOutlineClose id="btnClose-logo" size={20} />
                            </Button>
                            <section className="text-gray-600 body-font">
                                <div className="container px-5 py-24 mx-auto">
                                    <div className="flex flex-col text-center w-full ">
                                        <h1 className="lg:w-2/3 mx-auto leading-relaxed text-base py-3 mb-2">{truncateText("Newspaper plans")}</h1>
                                    </div>
                                    <div className="lg:w-2/6 w-full mx-auto overflow-auto">

                                        {Data && Data.pricing_single?.length > 0 && (
                                            <table className="table table-bordered">
                                                <thead>
                                                <tr>
                                                    <th className="px-4 py-3 mt-3 title-font tracking-wider font-medium text-white text-sm bg-primary rounded-tl rounded-bl">Frequency</th>
                                                    <th className="px-3 py-3 title-font tracking-wider font-medium text-white text-sm bg-primary">Price</th>
                                                    <th className="px-3 py-3 title-font tracking-wider font-medium text-white text-sm bg-primary"></th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <>
                                                    {isLoading ? (
                                                        <div className="col-12 loading_data">
                                                            <Skeleton height={20} count={22} />
                                                        </div>
                                                    ) : (Data.pricing_single?.length >0 && Data.pricing_single.map((item) => (
                                                            <tr key={item.id} onClick={(el)=>addPlan(el,item)}>
                                                                <td className="px-4 py-3">{uFirst(item.frequency) }</td>
                                                                <td className="px-4 py-3 text-lg text-gray-900">{item.currency} {item.amount}</td>
                                                                <td className="w-10 text-center justify-content-center">
                                                                    <input className="form-check-input mt-md-3" type="radio"
                                                                           name="plan" id="plan" value={"single-"+item.frequency+"-"+item.amount+"-"+item.currency}/>
                                                                </td>
                                                            </tr>
                                                        ))
                                                    ) }
                                                </>

                                                </tbody>
                                            </table>
                                        )}
                                        {Data  && (
                                            <div className="justify-end text-end p-4 col-12 mx-auto">
                                                <a href="/more-pages" className="text-opacity-50 inline-flex items-center justify-center m-lg-3" data-abc="true">
                                                    <span>Terms & Conditions</span>
                                                    <i className='fa fa-right-arrow-alt mt-1 ml-2'></i>
                                                </a>

                                                <button type="submit" className="btn btn-primary justify-content-lg-end" onClick={()=>getPlan()}>Subscribe Now</button>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </section>
                        </div>
                        )}
                    </div>
                )}
            </Modal.Body>
        </Modal>
    )
}

export default RateCard;
