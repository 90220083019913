import React, {useContext, useEffect, useRef, useState} from "react";
import { Button, Dropdown, Offcanvas } from "react-bootstrap";
import {BiBell, BiCartAdd, BiUserCircle} from "react-icons/bi";
import { GiHamburgerMenu } from "react-icons/gi";
import {convertToSlug, isLogin, profileimgError, translate, truncateText} from "../utils";
import { Link, NavLink } from "react-router-dom";
import { loadLanguageLabels, selectCurrentLanguage, selectLanguages, setCurrentLanguage } from "../store/reducers/languageReducer";
import { FaAngleDown } from "react-icons/fa";
import { useSelector } from "react-redux";
import { selectUser } from "../store/reducers/userReducer";
import { counterData } from "../store/reducers/notificationbadgeReducer";
import { settingsData } from "../store/reducers/settingsReducer";
import { AiOutlineSearch } from "react-icons/ai";
import { SetSearchPopUp } from "../store/stateSlice/clickActionSlice";
import { store } from "../store/store";
import Cart from "./Cart";
import {CartContext} from "../utils/cart";

const MobilesideBar = ({ isuserRole, name, logout, onClickHandler, Data, modalShow, setModalShow, islogout, setIsLogout, handleShow, show, handleClose,ProfileModal, ...props }) => {
    const userData = useSelector(selectUser);

    const currentLanguage = useSelector(selectCurrentLanguage);

    const languagesData = useSelector(selectLanguages);

    const counterBadgeData = useSelector(counterData);

    const { cartItems} = useContext(CartContext);

    const toggle = () => {
        setshowModal(!showModal);
    };
    const [showModal, setshowModal] = useState(false);

    const settingsOnOff = useSelector(settingsData);

    // language change
    const languageChange = (name, code, id) => {
        loadLanguageLabels(code);
        setCurrentLanguage(name, code, id);
    };

    useEffect(() => {
        loadLanguageLabels(currentLanguage.code);
    }, [currentLanguage]);

    const closeRef = useRef();

    let userName = "";

    const checkUserData = (userData) => {
        if (userData.data && userData.data.name !== "") {
            return (userName = userData.data.name);
        } else if (userData.data && userData.data.email !== "") {
            return (userName = userData.data.email);
        } else if (userData.data && (userData.data.mobile !== null || userData.data.mobile !== "")) {
            return (userName = userData.data.mobile);
        }
    };

    const searchPopUp = useSelector((state) => state.clickAction.searchPopUp);
    const actionSearch = () => {
        store.dispatch(SetSearchPopUp(!searchPopUp));
    };

    return (
        <>
            <button className="btn" onClick={handleShow}>
                <GiHamburgerMenu />
            </button>

            <Offcanvas id="Nav-Offcanvas" className="headermodal" show={show} onHide={handleClose} {...props}>
                <Offcanvas.Header closeButton ref={closeRef}>
                    <Offcanvas.Title>
                        <li id="Nav-btns">
                            {islogout && checkUserData(userData) ? (
                                <Dropdown>
                                    <Dropdown.Toggle id="btnSignIn" className="">
                                        <img className="profile_photo" src={userData.data && userData.data.profile ? userData.data.profile : process.env.PUBLIC_URL + "/images/user.svg"} onError={profileimgError} alt="profile"/>
                                        {truncateText(userName,10)}
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu style={{ backgroundColor: "#1A2E51" }}>
                                        {/*<Dropdown.Item id="btnLogout">*/}
                                        {/*    <Link id="btnBookmark" to="/bookmark" onClick={handleClose}>*/}
                                        {/*        {translate("bookmark")}*/}
                                        {/*    </Link>*/}
                                        {/*</Dropdown.Item>*/}
                                        <Dropdown.Item id="btnLogout">
                                            <a id="btnBookmark" href="/payment-history" onClick={handleClose}>
                                                {translate("payment-history")}
                                            </a>
                                        </Dropdown.Item>

                                        {/*<Dropdown.Item id="btnLogout" onClick={handleClose}>*/}
                                        {/*    <Link id="btnBookmark" to="/user-based-categories">*/}
                                        {/*        {translate("managePreferences")}*/}
                                        {/*    </Link>*/}
                                        {/*</Dropdown.Item>*/}
                                        {isuserRole ?
                                            <>
                                                {/*<Dropdown.Item id="btnLogout">*/}
                                                {/*    <Link id="btnBookmark" to="/create-news" onClick={() => handleClose()}>*/}
                                                {/*        {translate("createNewsLbl")}*/}
                                                {/*    </Link>*/}
                                                {/*</Dropdown.Item>*/}

                                                <Dropdown.Item id="btnLogout">
                                                    <a id="btnBookmark" href="/manage-news" onClick={() => handleClose()}>
                                                        {translate("manageNewsLbl")}
                                                    </a>
                                                </Dropdown.Item>
                                            </>
                                        : null}
                                        {/*<Dropdown.Item id="btnLogout">*/}
                                        {/*    <Link id="btnBookmark" onClick={() => { ProfileModal(true);  handleClose()}}>*/}
                                        {/*        {translate("update-profile")}*/}
                                        {/*    </Link>*/}
                                        {/*</Dropdown.Item>*/}
                                        <Dropdown.Divider />
                                        <Dropdown.Item onClick={logout} id="btnLogout" className="">
                                            {translate("logout")}
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            ) : (
                                <Button variant="danger" onClick={() => setModalShow(true)} id="btnSignIn" className="" type="button">
                                    <BiUserCircle size={23} id="btnLogo" />
                                    {translate("login")}
                                </Button>
                            )}
                        </li>

                        <li id="Nav-btns">
                            <Dropdown>
                                <Dropdown.Toggle id="btnSignIn" className="">
                                    {currentLanguage.name}
                                </Dropdown.Toggle>

                                <Dropdown.Menu style={{ backgroundColor: "#1A2E51" }}>
                                    {languagesData &&
                                        languagesData.map((data, index) => {
                                            return (
                                                <Dropdown.Item key={index} id="btnLogout" onClick={() => languageChange(data.language, data.code, data.id)}>
                                                    {data.language}
                                                </Dropdown.Item>
                                            );
                                        })}
                                </Dropdown.Menu>
                            </Dropdown>
                        </li>
                        <li id="Nav-btns">
                            {islogout && checkUserData(userData) ? (
                                <a href="/notification" id="btnNotification" type="button" className="btn" onClick={()=>handleClose()} >
                                    <BiBell size={23} />
                                    <span className="noti_badge_data">{counterBadgeData && counterBadgeData.counter}</span>
                                </a>
                            ) : null}
                        </li>

                        <li id="Nav-btns">
                            <Link  onClick={toggle}  id="btnNotification" type="button" className="btn">
                                <BiCartAdd size={23} /><span className="noti_badge_data cart_badge_data">{cartItems.length}</span>
                            </Link>
                        </li>
                        {/* searchbar */}
                        <li id="Nav-btns" className="mt-2">
                            <a
                                id="btnNotification"
                                type="button"
                                className="btn"
                                onClick={() => {
                                    actionSearch();
                                    handleClose();
                                }}
                            >
                                <AiOutlineSearch size={23} />
                            </a>
                        </li>
                    </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <ul className="">
                        <li className="nav-item">
                            <b>
                                <NavLink activeclassname="active" exact="true" id="nav-links" className="" aria-current="page" to="/" onClick={handleClose}>
                                    {translate("home")}
                                </NavLink>
                            </b>
                        </li>

                        <li id="NavHover" className="nav-item">
                            <NavLink id="nav-links" activeclassname="active" exact="true" className="link-color"
                                     aria-current="page" to="/newspaper" onClick={handleClose}>
                                {translate("newspaper")}
                            </NavLink>
                        </li>
                        <li id="NavHover" className="nav-item">
                            <NavLink id="nav-links" activeclassname="active" exact="true" className="link-color"
                                     aria-current="page" to="/magazine" onClick={handleClose}>
                                {translate("magazine")}
                            </NavLink>
                        </li>
                        <li id="NavHover" className="nav-item">
                            <NavLink id="nav-links" activeclassname="active" exact="true" className="link-color"
                                     aria-current="page" to="/books" onClick={handleClose}>
                                {translate("books")}
                            </NavLink>
                        </li>
                        <li id="NavHover" className="nav-item">
                            <NavLink id="nav-links" activeclassname="active" exact="true" className="link-color"
                                     aria-current="page" to="/report" onClick={handleClose}>
                                {translate("financial report")}
                            </NavLink>
                        </li>
                        {settingsOnOff && settingsOnOff.live_streaming_mode === "1" ?
                            <li id="NavHover" className="nav-item">
                                <NavLink id="nav-links" activeclassname="active" exact="true"
                                         className="link-color" aria-current="page" to="/live-news" onClick={handleClose}>
                                    {translate("live")}
                                </NavLink>
                            </li>
                            : null}

                        <li id="NavHover" className="nav-item">
                            <NavLink id="nav-links" activeclassname="active" exact="true" className="link-color"
                                     aria-current="page" to="/archives" onClick={handleClose}>
                                {translate("archives")}
                            </NavLink>
                        </li>
                        <li id="NavHover" className="nav-item">
                            <NavLink id="nav-links" activeclassname="active" exact="true"
                                     className="link-color" aria-current="page" to="/more-pages" onClick={handleClose}>
                                {translate("More Pages")}
                            </NavLink>
                        </li>
                    </ul>
                </Offcanvas.Body>
            </Offcanvas>
            <li id="Nav-btns">
                <Link  onClick={toggle}  id="btnNotification" type="button" className="btn">
                    <BiCartAdd size={23} /><span className="noti_badge_data cart_badge_data">{cartItems.length}</span>
                </Link>
            </li>

            <Cart showModal={showModal} toggle={toggle} />
        </>
    );
};

export default MobilesideBar;
