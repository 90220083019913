import React, { useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {translate} from "../utils";
import {Container, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import {MobileView} from "react-device-detect";
import {useSelector} from "react-redux";
import {webSettingsData} from "../store/reducers/websettingsReducer";

function isMobileDevice() {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    return /android|iPad|iPhone|iPod|mobile/i.test(userAgent);
}

function isIOS() {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    return /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream;
}

const DeviceNotification = () => {

    const websettings = useSelector(webSettingsData);
    useEffect(() => {
        const hasShownMobileNotification = localStorage.getItem('hasShownMobileNotification');
    }, []);

    return <>
        {isMobileDevice() && (
            (
                <MobileView className={"mobile-noti bg-default"}>
                    <Container className={"pt-2 text-sm-center"}>
                        <Row>
                            <div className={"col-2 justify-content-center h-50"} >
                                <div className={"logo-bg rounded-3 w-100"}>
                                    <img id="" className={"w-100 align-content-center"} src={websettings && websettings.web_footer_logo} alt=""/>
                                </div>
                            </div>
                            <div className={"col-7 text-sm-center"}>
                                <h6 className={"strong"}><b>MwanaClick</b></h6>
                                <p className={"text-sm-center "}>{translate("Open in the MwanaClick app")}</p>
                            </div>
                            <div className={"col-3 justify-content-end align-content-center "}>
                                <Link to={isIOS() ? "//apps.apple.com/tz/app/mwanaclick/id6483937075" : '//play.google.com/store/apps/details?id=com.mcl.mwanaclick'} className={"rounded-5 bg-white text-default py-2 px-3 text-decoration-none"}>
                                    OPEN
                                </Link>
                            </div>
                        </Row>
                    </Container>
                </MobileView>
            )
        )}
    </>;
}

export default DeviceNotification;
